@import '../node_modules/@engineering/vpgroove-angular/src/styles/vpgroove-global-styles.min.css';
@import '../node_modules/@engineering/groove-css/dist/styles.min.css';
@import '../node_modules/fontawesome-4.7/css/font-awesome.min.css';

@import "variables.scss";
@import "fonts.scss";
@import "./styles/groove-override.scss";

html {
    height: 100%;
    width: 100%;
}

body {
    font-family: $main-font;
    background-size: cover;
    top: 0;
    height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: start;
}

input, select {
    padding: 1px 10px;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
    cursor: pointer;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    cursor: not-allowed;
}

.flex-form-field {
    padding-bottom: 15px;
}

.flex-form-sponsor-search-field {
    margin-bottom: 10px;
}

.no-side-padding {
    padding-left: 0;
    padding-right: 0;
}

.btn-orange {
    padding: 11px 50px;
    text-transform: uppercase;
    background-color:  $orange;
    background-image: $orange-gradient;
    box-shadow: inset 0 -2px 0 0 #F37421;
    border-radius: 100px;
    border: none;
    font-family: $bold-font;
    font-weight: 600;
    letter-spacing: 0.6px;
    color: #fff !important;
    display: block;
    margin: 0 auto;
    &:hover {
        background-image: $orange-gradient-reverse;
    }
    &:disabled {
        background-image: $gray-gradient;
        box-shadow: none;
        opacity: 1;
    }
}

.btn-orange-outline {
    padding: 6px 40px;
    text-transform: uppercase;
    background-color: #fff;
    border-radius: 100px;
    border: 2px solid $orange;
    font-family: $bold-font;
    letter-spacing: 0.6px;
    color: $orange;
    display: block;
    &:hover {
        background-color: $orange;
        color: #fff;
    }
}

a {
    color: $teal;
    &:hover {
        color: #009FA1;
        text-decoration: none;
        cursor: pointer;
    }
}

.still-need-help a {
    color: var(--VPGrooveColor-LinkColor);
    &:hover {
        color: var(--VPGrooveColor-LinkColor);
        text-decoration: none;
        cursor: pointer;
    }
}

strong {
    font-family: $bold-font;
}

.page-title {
    font-family: $semibold-font;
    font-size: 28px;
    color: $dark-slate;
    text-align: center;
    margin: 0 0 25px;
}

.form-group {
    margin-bottom: 30px;
    label {
        font-family: $bold-font;
        text-transform: uppercase;
        color: $medium-gray;
        letter-spacing: 0.6px;
        font-size: 12px;
    }
    .form-input {
        width: 100%;
        display: block;
        border: 1px solid $light-gray;
        height: 44px;
        border-radius: 0;
        font-size: 16px;
        &:focus, &:active {
            border: 1px solid rgba(34,174,179,0.5);
            outline-color: rgba(85,159,168,0.8);
            box-shadow: 0 0 4px 1px rgba(85,159,168,0.8);
        }
    }
    .intl-tel-input, .phone-number {
        width: 100%;
    }
    .field-error {
        padding: 0 2px;
        float: left;
        color: $error-red;
        height: 0;
    }
    &.form-group-name {
        label {
            display: block;
        }
    }
    .validation-error.ng-touched {
        border: 1px solid $error-red;
        box-shadow: 0 0 3px 1px rgba(208,1,26,0.40);
    }
    .input-length-error.ng-dirty {
        border: 1px solid $error-red;
        box-shadow: 0 0 3px 1px rgba(208,1,26,0.40);
    }
}

.error-text {
    font-family: $semibold-font;
    font-size: 11px;
    color: $error-red;
    letter-spacing: 0.2px;
    line-height: 20px;
}

.note {
    font-size: 15px;
    margin-bottom: 25px;
}

.popover {
    background-color: #F5F5F4;
    font-family: $main-font;

     .arrow {
         left: 125px !important;
         right: 3px;
         &:after {
             border-top-color: #F5F5F4 !important;
         }
     }
}

select {
    background: transparent;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-ms-clear,
input::-ms-reveal {
    display: none;
}

.child-width-100 {
    .iti {
        display: block !important;
    }
}

.display-none {
    display: none;
}

.visibility-hidden {
    visibility: hidden;
}

.v-button {
    &.is-large {
        --button-font-size: 1rem !important;
    }
}

.v-dialog {
    border-radius: 0 !important;
}

.v-dialog-header {
    .v-dialog-title {
        font-size: 1rem !important;
        color: var(--v-color-neutral) !important;
        font-weight: bold !important;
    }

    .v-dialog-close-button.is-large {
        width: 4rem !important;
        height: 4rem !important;
            .v-icon {
                color: var(--v-color-neutral-90) !important;
            }
    }
}

.v-dialog-body {
    h1, h2, h3, h4, h5, h6 {
        font-size: 1rem !important;
        font-weight: bold !important;
    }

    a, a:hover {
        color: var(--VPGrooveColor-LinkColor) !important;
        font-weight: bold !important;
    }
}

.v-input-field {
    label {
        letter-spacing: 0 !important;
        font-size: 1rem !important;
        font-family: "Open Sans", sans-serif !important;
        text-transform: none !important;
        line-height: 1.4rem !important;
        color: var(--v-color-neutral) !important;

        .v-control-required {
            font-size: .7rem !important;
            font-weight: normal !important;
            color: #212529 !important;
        }
    }

    .v-control-input, .v-icon {
        font-size: 1rem !important;
    }

    .v-control-input {
        &::placeholder {
            color: var(--v-color-neutral) !important;
        }

        &:disabled {
            background: #EFEFEF4D !important;
        }
    }

    .v-control-error {
        font-family: 'Open Sans Semibold', Arial, Helvetica, sans-serif !important;
        font-size: 11px !important;
        color: #d0011b !important;
        letter-spacing: .2px !important;
        line-height: 20px !important;
    }

    .justify-content-between {
        display: none !important;
    }
}

.v-inline-label {
    .v-inline-label-text {
        font-size: 1rem !important;
        line-height: 1.3rem !important;
        text-align: left !important;

        a {
            color: var(--VPGrooveColor-LinkColor) !important;
            font-weight: bold !important;
        }
    }

    .v-icon {
        font-size: 1.6rem !important;
    }
}

.v-control-list {
    max-height: 183px !important;
    .v-control-option {
        font-size: 1rem !important;
    }
}

.v-control-empty-list {
    .v-control-empty-list-heading, .v-control-empty-list-text {
        font-size: 1rem !important;
    }
}

.v-checkbox-link-text {
    background: none !important;
    border: none !important;
    color: var(--VPGrooveColor-LinkColor) !important;
    padding: 0 !important;
}

.v-checkbox-link-text:hover {
    text-decoration: underline !important;
}

.v-control-label .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0 !important;
}

#submit-button-container, .enroll-success-container {
    .v-button-element {
        margin: 10px 0 5px !important;
    }
}
